import React from 'react';
import logo from '../assets/fpimages/logo.svg'
import fb from '../assets/fpimages/fb.svg'
import tw from '../assets/fpimages/twitter.svg'
import yt from '../assets/fpimages/youtube.svg'
import li from '../assets/fpimages/linkedin.svg'
import ig from '../assets/fpimages/instagram.svg'
import { Link } from 'react-router-dom';




function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-5">
            <img src={logo} alt="Logo" />
            <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          <div className="col-7">
            <div className="footer-items">
              <div>
                <h5>Contact information</h5>
                <ul>
                  <li><Link to="/about" className="nav-link">About Us</Link></li>
                  <li><Link to="/contact" className="nav-link">Contact</Link></li>
                </ul>
              </div>
              <div>
                <h5>Opening hours</h5>
                <ul>
                  <li><p className="footer-link">Monday - Friday: 07.30 - 17.00</p></li>
                  <li><p className="footer-link">Saturday - Sunday: Closed</p></li>
                </ul>
              </div>
              <div>
                <h5>Our location</h5>
                <ul>
                  <li><p className="footer-link">Forskerparken Videnbyen</p></li>
                  <li><p className="footer-link">Cortex park 26</p></li>
                  <li><p className="footer-link">5230 Odense M</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="social-links">
          <ul>
            <li><img src={fb} alt="Facebook" /></li>
            <li><img src={tw} alt="Twitter" /></li>
            <li><img src={yt} alt="YouTube" /></li>
            <li><a href="https://dk.linkedin.com/company/sharepart?trk=public_jobs_topcard-org-name"><img src={li} alt="LinkedIn" /></a></li>
            <li><img src={ig} alt="Instagram" /></li>
          </ul>
        </div>
        <div className="footer-bottom">
          <p>© 2024 SharePart ApS - Forskerparken Videnbyen - Cortex park 26 - 5230 Odense M - Denmark - Tel: +45 3152 5240 - CVR: 38556673</p>
          <ul>
            <li><span>Privacy</span></li>
            <li><span>Accessibility</span></li>
            <li><span>Terms & Conditions</span></li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
