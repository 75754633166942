import React, { useState } from 'react';
import oliverImage from '../assets/fpimages/oliver.jpg'; // Import the image
import davidImage from '../assets/fpimages/david.jpg'; // Import the image
import madsImage from '../assets/fpimages/mads.jpg'; // Import the image

function Team() {
  const [selectedMember, setSelectedMember] = useState(null);

  const selectMember = (index) => {
    setSelectedMember(index);  // Set the selected member's index
  };

  const teamData = [
    {
      name: 'Oliver A. Bendorf',
      role: 'Economics and Business Administration',
      image: oliverImage,
    },
    {
      name: 'David Ehlert Binggeli',
      role: 'Manufacturing and Management Engineering',
      image: davidImage,
    },
    {
      name: 'Mads Hede Kieler',
      role: 'Manufacturing and Management Engineering',
      image: madsImage,
    },
  ];

  return (
    <section className="team-section">
      <h1 className="team-title">Meet the team behind SharePart</h1>
      <div className="team-container">
        <div className="team-list">
          {teamData.map((member, index) => (
            <div
              className="team-member"
              onClick={() => selectMember(index)}
              key={index}
            >
              <div
                className="member-photo"
                style={{ backgroundImage: `url(${member.image})` }} // Use the imported image
              ></div>
              <div className="member-info">
                <h2 className="member-name">{member.name}</h2>
                <p className="member-role">{member.role}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="team-details">
          {/* Show selected member details */}
          {selectedMember !== null && (
            <div className="detail-header">
              <div
                className="detail-photo"
                style={{ backgroundImage: `url(${teamData[selectedMember].image})` }} // Use the selected member's image
              ></div>
              <div className="detail-info">
                <h2 className="detail-name">{teamData[selectedMember].name}</h2>
                <p className="detail-role">{teamData[selectedMember].role}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Team;
