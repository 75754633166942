import React from 'react';
import teamphoto from '../assets/fpimages/team.png';
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <section className="contact-section">
      <div className="contact-container">
        <div className="contact-text">
          <p className="tagline">TAKE THE FIRST STEP</p>
          <h1>Get in contact and get started</h1>
          <Link to="/contact" className="contact-btn">Contact us</Link>
        </div>
        <div className="contact-image">
          <img src={teamphoto} alt="Our Team" />
        </div>
      </div>
    </section>
  );
}

export default Contact;
