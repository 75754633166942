import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';  // Import the HomePage component
import LoginPage from './pages/LoginPage';  // Import the LoginPage component
import PricingPage from './pages/PricingPage';
import Footer from './components/Footer'; // Import Footer component
import './styles/homepage.css'
import './styles/responsive.css'
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';

function App() {
  return (
    <Router>
      <Routes>
        {/* Define routes for HomePage and LoginPage */}
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
      <Footer />  {/* Footer will be displayed on every page */}
    </Router>
  );
}

export default App;
