import React from 'react';
import { Link } from 'react-router-dom';

function Hero() {
  return (
    <section className="hero-sec">
      <div className="container">
        <div className="text-box">
          <span className="tag-line">TAGLINE</span>
          <h1>Share your spare parts across businesses</h1>
          <p>
            The SharePart platform enables businesses to seamlessly share spare parts and access the inventories of other companies.
          </p>
          <ul className="btns-list">
            <li>
              <Link to="/contact" className="btn-success">Contact us</Link>
            </li>
            <li>
              <Link to="/login" className="btn-default">Log in</Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Hero;
