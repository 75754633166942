import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';  // Import App component

ReactDOM.render(
  <React.StrictMode>
    <App />  {/* Render the App component */}
  </React.StrictMode>,
  document.getElementById('root')  // React injects the components here
);
