import React from 'react';

function Platform() {
  return (
    <section className="platform-sec">
      <div className="glow-bg">
        <div className="vector"></div>
      </div>
      <div className="platform-container">
        <div className="platform-header">
          <span className="platform-tag-line">HOW DOES IT WORK?</span>
          <h1 className="platform-title">How the platform works</h1>
        </div>
        <div className="platform-steps">
          <div className="platform-step">
            <h2 className="step-number">01</h2>
            <h3 className="step-title">Create an account</h3>
            <p className="step-description">Increase your spare parts sales by joining the SharePart platform.</p>
          </div>
          <div className="platform-step">
            <h2 className="step-number">02</h2>
            <h3 className="step-title">Upload spare parts</h3>
            <p className="step-description">Increase your spare parts sales by joining the SharePart platform.</p>
          </div>
          <div className="platform-step">
            <h2 className="step-number">03</h2>
            <h3 className="step-title">Request spare part</h3>
            <p className="step-description">Increase your spare parts sales by joining the SharePart platform.</p>
          </div>
          <div className="platform-step">
            <h2 className="step-number">04</h2>
            <h3 className="step-title">Receive spare part</h3>
            <p className="step-description">Increase your spare parts sales by joining the SharePart platform.</p>
          </div>
          <div className="platform-step">
            <h2 className="step-number">05</h2>
            <h3 className="step-title">Resume operations</h3>
            <p className="step-description">Increase your spare parts sales by joining the SharePart platform.</p>
          </div>
        </div>
        <div className="platform-button">
          <span className="get-started-btn">Get started</span>
        </div>
      </div>
    </section>
  );
}

export default Platform;
