import React from 'react';
import '../styles/PricingPage.css';
import Header from '../components/Header'; 

function PricingPage() {
  return (
    <div>
      <Header />
      <div className="pricing-container">
        <div className="pricing-header">
        <h1>Simple, Transparent Pricing</h1>
        <p>Choose the plan that's right for you</p>
      </div>

      <div className="pricing-grid">
        {/* Basic Plan */}
        <div className="pricing-card">
          <h2>Basic</h2>
          <p className="plan-description">Perfect for getting started</p>
          <div className="price">
            <span className="amount">$9</span>
            <span className="period">/month</span>
          </div>
          <ul className="features">
            <li>Basic feature 1</li>
            <li>Basic feature 2</li>
            <li>Basic feature 3</li>
          </ul>
          <button className="cta-button">Get Started</button>
        </div>

        {/* Pro Plan */}
        <div className="pricing-card popular">
          <div className="popular-badge">Most Popular</div>
          <h2>Pro</h2>
          <p className="plan-description">Most popular choice</p>
          <div className="price">
            <span className="amount">$29</span>
            <span className="period">/month</span>
          </div>
          <ul className="features">
            <li>Everything in Basic</li>
            <li>Pro feature 1</li>
            <li>Pro feature 2</li>
            <li>Pro feature 3</li>
          </ul>
          <button className="cta-button">Get Started</button>
        </div>

        {/* Enterprise Plan */}
        <div className="pricing-card">
          <h2>Enterprise</h2>
          <p className="plan-description">For larger organizations</p>
          <div className="price">
            <span className="amount">$99</span>
            <span className="period">/month</span>
          </div>
          <ul className="features">
            <li>Everything in Pro</li>
            <li>Enterprise feature 1</li>
            <li>Enterprise feature 2</li>
            <li>Enterprise feature 3</li>
          </ul>
          <button className="cta-button">Contact Sales</button>
        </div>
      </div>
      </div>
    </div>
  );
}

export default PricingPage;
