import React from 'react';
import logo1 from '../assets/fpimages/logo-1.svg';
import logo2 from '../assets/fpimages/logo-2.svg';
import logo3 from '../assets/fpimages/logo-3.svg';
import logo4 from '../assets/fpimages/logo-4.svg';
import logo5 from '../assets/fpimages/logo-5.svg';

function Partner() {
  return (
    <section className="partner-sec">
      <div className="container">
        <div className="row">
          <div className="col-3">
            <span>Proud to work together with:</span>
          </div>
          <div className="col-9">
            <ul className="partner-logo">
              <li><img src={logo1} alt="Partner 1" /></li>
              <li><img src={logo2} alt="Partner 2" /></li>
              <li><img src={logo3} alt="Partner 3" /></li>
              <li><img src={logo4} alt="Partner 4" /></li>
              <li><img src={logo5} alt="Partner 5" /></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partner;
