import React from 'react';
import scenario from '../assets/fpimages/scenario.png'

function Scenario() {
  return (
    <section className="scenario-sec">
      <div className="scenario-container">
        <div className="scenario-text-box">
          <span className="scenario-tag-line">TAGLINE</span>
          <h1 className="scenario-title">Imagine this scenario</h1>
          <p className="scenario-description">
            A critical machine experiences downtime. Instead of waiting for a distant supplier, businesses can connect for a quicker resolution.
          </p>
          <span className="scenario-btn">Get started</span>
        </div>
        <div className="scenario-image-box">
          <img src={scenario} alt="Warehouse scenario" className="scenario-image" />
        </div>
      </div>
    </section>
  );
}

export default Scenario;
