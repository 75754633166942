import React, { useState } from 'react';
import oliverImage from '../assets/fpimages/oliver.jpg';
import davidImage from '../assets/fpimages/david.jpg';
import madsImage from '../assets/fpimages/mads.jpg';
import '../styles/AboutPage.css';
import Header from '../components/Header';  

function AboutPage() {
  const [selectedMember, setSelectedMember] = useState(null);

  const teamData = [
    {
      name: 'Oliver A. Bendorf',
      role: 'Economics and Business Administration',
      image: oliverImage,
    },
    {
      name: 'David Ehlert Binggeli',
      role: 'Manufacturing and Management Engineering',
      image: davidImage,
    },
    {
      name: 'Mads Hede Kieler',
      role: 'Manufacturing and Management Engineering',
      image: madsImage,
    },
  ];

  return (
    <div>
      <Header />
    
      <div className="about-container">
        {/* Mission Section */}
      <section className="mission-section">
        <div className="section-content">
          <h2>Our Mission</h2>
          <p>
            We're dedicated to transforming the digital landscape by creating
            innovative solutions that empower businesses and individuals alike.
            Through cutting-edge technology and exceptional service, we strive to
            make a lasting impact in the tech industry.
          </p>
        </div>
      </section>

      {/* Values Section */}
      <section className="values-section">
        <h2>Our Values</h2>
        <div className="values-grid">
          <div className="value-card">
            <h3>Innovation</h3>
            <p>Constantly pushing boundaries and exploring new possibilities</p>
          </div>
          <div className="value-card">
            <h3>Excellence</h3>
            <p>Committed to delivering the highest quality in everything we do</p>
          </div>
          <div className="value-card">
            <h3>Integrity</h3>
            <p>Building trust through transparency and honest relationships</p>
          </div>
          <div className="value-card">
            <h3>Collaboration</h3>
            <p>Working together to achieve extraordinary results</p>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="team-section">
        <h1 className="team-title">Meet the team behind SharePart</h1>
        <div className="team-container">
          <div className="team-list">
            {teamData.map((member, index) => (
              <div
                className="team-member"
                onClick={() => setSelectedMember(index)}
                key={index}
              >
                <div
                  className="member-photo"
                  style={{ backgroundImage: `url(${member.image})` }}
                ></div>
                <div className="member-info">
                  <h2 className="member-name">{member.name}</h2>
                  <p className="member-role">{member.role}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="team-details">
            {selectedMember !== null && (
              <div className="detail-header">
                <div
                  className="detail-photo"
                  style={{ backgroundImage: `url(${teamData[selectedMember].image})` }}
                ></div>
                <div className="detail-info">
                  <h2 className="detail-name">{teamData[selectedMember].name}</h2>
                  <p className="detail-role">{teamData[selectedMember].role}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Story Section */}
      <section className="story-section">
        <div className="section-content">
          <h2>Our Story</h2>
          <p>
            Founded in 2020, we began with a simple vision: to make technology
            more accessible and user-friendly for everyone. What started as a
            small team of passionate developers has grown into a dynamic
            company serving clients worldwide.
          </p>
          <p>
            Today, we continue to grow and innovate, always staying true to
            our core mission of delivering excellence in every project we
            undertake.
          </p>
        </div>
      </section>
    </div>
    </div>
  );
}

export default AboutPage; 